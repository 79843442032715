import { toast } from 'react-toastify';

function toastArgs() {
  const args = {
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: 6000,
    hideProgressBar: true,
    pauseOnFocusLoss: false,
  };
  return args;
}

export const toastSuccess = (message) => {
  toast.success(message, toastArgs());
};

export const toastError = (message) => {
  toast.error(message, toastArgs());
};
