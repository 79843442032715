import ReactGA from 'react-ga4'
//https://github.com/codler/react-ga4#readme
function init() {
 ReactGA.initialize([{
    trackingId: process.env.GOOGLE_ANALYTICS_TRACKING_ID,
 }])
}

function sendEvent(payload) {
  ReactGA.event(payload);
}

function sendPageview(path) {
  ReactGA.send({ page: path , hitType: "pageview"});
}

export default {
  init,
  sendEvent,
  sendPageview,
};
