/* eslint-disable  react/jsx-props-no-spreading */
import { useMemo, useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import {
  Card,
  Col,
  Container,
  Row,
  Button,
  ButtonGroup,
  Table,
  Pagination,
  Form,
} from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import { useIsAuthenticated, useMsal, useAccount } from '@azure/msal-react';
import { useQuery, useMutation, NetworkStatus } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useTable, usePagination } from 'react-table';
import get from 'lodash.get';
import includes from 'lodash.includes';
import { DateTime } from 'luxon';
import { toastSuccess, toastError } from '../lib/toast';

import {
  renderUnauthorized,
  renderUnauthenticated,
  renderOverlay,
  renderError,
  releaseMessages,
} from '../components/render_helpers';
import Confirm from '../components/confirm';
import pageQuarantineListQuery from '../queries/page_quarantine_list_query';
import { NotificationActionTypes, DisplayQuarantineTypes } from '../lib/constants';
import quarantineReleaseMutation from '../mutations/quarantine_release_mutation';
import optimistic from '../lib/optimistic';
import googleAnalytics from '../lib/google_analytics';
import applicationInsights from '../lib/application_insights';
import quarantineReleaseUpdateFunction from '../update_functions/quarantine_release_update_function';
import useSettings from '../hooks/use_settings';

const QuarantineList = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 812px)' });
  const isAuthenticated = useIsAuthenticated();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [quarantineList, setQuarantineList] = useState([]);
  const {
    isMutating,
    setIsMutating,
    quarantineListIncludeReleased,
    setQuarantineListIncludeReleased,
  } = useSettings();
  const [pageLoadedOrRefetching, setPageLoadedOrRefetching] = useState(false);

  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
    // refetch: pageRefetch,
    networkStatus: pageNetworkStatus,
  } = useQuery(pageQuarantineListQuery, {
    pollInterval: 900000,
    notifyOnNetworkStatusChange: true,
  });

  const [quarantineRelease] = useMutation(quarantineReleaseMutation);

  useEffect(() => {
    setIsAuthorized(!!get(pageData, 'settings.quarantineSelfRelease'));
    setQuarantineList(
      get(pageData, 'quarantineList', []).filter((q) =>
        quarantineListIncludeReleased ? q : !q.released
      )
    );
  }, [pageData, quarantineListIncludeReleased]);

  useEffect(() => {
    setPageLoadedOrRefetching(
      !pageLoading ||
        (pageLoading &&
          (pageNetworkStatus === NetworkStatus.refetch ||
            pageNetworkStatus === NetworkStatus.poll))
    );
  }, [pageLoading, pageNetworkStatus]);

  // const handleRefetch = () => pageRefetch();

  const handleShow = useCallback(
    (e) => {
      const id = e.currentTarget.getAttribute('data-id');
      navigate(`/search/quarantine/${id}`);
    },
    [navigate]
  );

  const handleRelease = useCallback(
    async (e) => {
      const id = e.currentTarget.getAttribute('data-id');
      const quarantineItem = quarantineList.find((qi) => qi.id === id);
      const mutationData = {
        variables: { input: { id, recipient: quarantineItem.recipient } },
        context: {
          recordType: 'QuarantineType',
          recordId: id,
          mutationType: 'UPDATE',
        },
        update: quarantineReleaseUpdateFunction,
      };
      mutationData.optimisticResponse = optimistic('quarantineRelease', mutationData, {
        released: false,
      });
      try {
        setIsMutating(true);
        await quarantineRelease(mutationData);
        googleAnalytics.sendEvent({
          category: 'User',
          action: 'ShowReleaseSuccess',
        });
        applicationInsights.sendEvent({
          name: 'ShowReleaseSuccess',
        });
        toastSuccess('Message released');
      } catch (err) {
        console.log(err.toString());
        googleAnalytics.sendEvent({
          category: 'User',
          action: 'ShowReleaseFailure',
        });
        applicationInsights.sendEvent({
          name: 'ShowReleaseFailure',
        });
        toastError('Message release failed');
      } finally {
        setIsMutating(false);
      }
    },
    [quarantineList, quarantineRelease, setIsMutating]
  );

  const renderActionCell = useCallback(
    ({ row }) => {
      const {
        original: { id, released },
      } = row;
      const actions = get(pageData, 'settings.quarantineSelfRelease.actions', []);
      return (
        <ButtonGroup>
          <Button
            variant="primary"
            className="me-1"
            size="sm"
            data-id={id}
            onClick={handleShow}
            disabled={!includes(actions, NotificationActionTypes.REVIEW)}
          >
            review
          </Button>
          {includes(actions, NotificationActionTypes.REVIEW) &&
            includes(actions, NotificationActionTypes.RELEASE) &&
            includes(actions, NotificationActionTypes.UNREVIEWED_RELEASE) && (
              <Confirm
                onConfirm={handleRelease}
                dataId={id}
                title="Release Message"
                body={releaseMessages(released)}
                confirmText="Confirm"
              >
                <Button variant="primary" size="sm">
                  release
                </Button>
              </Confirm>
            )}
        </ButtonGroup>
      );
    },
    [pageData, handleRelease, handleShow]
  );

  const columns = useMemo(() => {
    return [
      {
        Header: 'Received',
        // todo replace this with only messageMarkerDateTimeUtc when mark provides
        Cell: ({ row }) => {
          const {
            original: { messageMarkerDateTimeUtc, createdAtDateTimeUtc },
          } = row;
          return DateTime.fromISO(messageMarkerDateTimeUtc || createdAtDateTimeUtc)
            .setLocale('en-nz')
            .toLocaleString(DateTime.DATETIME_SHORT);
        },
      },
      {
        Header: 'From',
        Cell: ({ row }) => {
          const {
            original: { fromHeader, sender },
          } = row;
          return fromHeader || sender;
        },
      },
      // {
      //   Header: 'Recipient',
      //   accessor: 'recipient',
      // },
      {
        Header: 'Subject',
        accessor: 'subject',
      },
      ...(isMobile
        ? []
        : [
            {
              Header: 'Reason',
              Cell: ({ row }) => {
                const {
                  original: { quarantineRuleName, quarantineType },
                } = row;
                return quarantineRuleName
                  ? `Rule: ${quarantineRuleName}`
                  : DisplayQuarantineTypes[quarantineType];
              },
            },
            {
              Header: 'Released',
              accessor: 'released',
              Cell: ({ value }) => (value ? 'Yes' : 'No'),
            },
            {
              Header: 'Expires',
              accessor: 'expiresAtDateTimeUtc',
              Cell: ({ row }) => {
                const {
                  original: { expiresAtDateTimeUtc },
                } = row;
                return DateTime.fromISO(expiresAtDateTimeUtc)
                  .setLocale('en-nz')
                  .toLocaleString(DateTime.DATE_SHORT);
              },
            },
          ]),
      {
        Header: 'Actions',
        columnClassName: 'text-center',
        Cell: renderActionCell,
      },
    ];
  }, [renderActionCell, isMobile]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data: quarantineList,
      initialState: { pageIndex: 0 },
    },
    usePagination
  );

  const renderContent = () => {
    return (
      <>
        <Helmet title="Quarantine List" />
        <Container fluid className="p-0">
          <Row>
            <Col sm={12}>
              <div className="float-none">
                <div className="float-start">
                  <h1 className="h3 mb-3">{`Quarantined items for ${get(
                    account,
                    'name',
                    'user'
                  )}`}</h1>
                </div>
                {/* <div className="float-end">
                  <Button
                    variant="primary"
                    onClick={handleRefetch}
                    disabled={pageLoading}
                  >
                    Refresh
                  </Button>
                </div> */}
                <div className="float-end">
                  <Form.Group
                    className="mt-1 me-4"
                    controlId="quarantineListIncludeReleased"
                  >
                    <Form.Check
                      type="checkbox"
                      label="Include Released"
                      checked={quarantineListIncludeReleased}
                      onChange={() =>
                        setQuarantineListIncludeReleased(!quarantineListIncludeReleased)
                      }
                    />
                  </Form.Group>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  <Table responsive striped bordered {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => {
                            return (
                              <th
                                {...(column.columnClassName && {
                                  className: column.columnClassName,
                                })}
                                {...column.getHeaderProps()}
                              >
                                {column.render('Header')}
                              </th>
                            );
                          })}
                        </tr>
                      ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td
                                  {...(cell.column.columnClassName && {
                                    className: cell.column.columnClassName,
                                  })}
                                  {...cell.getCellProps()}
                                >
                                  {cell.render('Cell')}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <Row>
                    <Col md="6">
                      <span className="mx-2">
                        Page{' '}
                        <strong>
                          {pageIndex + 1} of {pageOptions.length}
                        </strong>
                      </span>
                      <span className="ms-3 me-2">Show:</span>
                      <Form.Select
                        className="d-inline-block w-auto"
                        value={pageSize}
                        onChange={(e) => {
                          setPageSize(Number(e.target.value));
                        }}
                      >
                        {[10, 20, 30, 40, 50].map((size) => (
                          <option key={size} value={size}>
                            {size}
                          </option>
                        ))}
                      </Form.Select>

                      <span className="ms-3 me-2">Go to page:</span>
                      <Form.Control
                        className="d-inline-block"
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={(e) => {
                          const pageNumber = e.target.value
                            ? Number(e.target.value) - 1
                            : 0;
                          gotoPage(pageNumber);
                        }}
                        style={{ width: '75px' }}
                      />
                    </Col>
                    <Col md="6">
                      <Pagination className="float-end">
                        <Pagination.First
                          onClick={() => gotoPage(0)}
                          disabled={!canPreviousPage}
                        />
                        <Pagination.Prev
                          onClick={() => previousPage()}
                          disabled={!canPreviousPage}
                        />
                        <Pagination.Next
                          onClick={() => nextPage()}
                          disabled={!canNextPage}
                        />
                        <Pagination.Last
                          onClick={() => gotoPage(pageCount - 1)}
                          disabled={!canNextPage}
                        />
                      </Pagination>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  };

  return (
    <div>
      {renderUnauthenticated(isAuthenticated)}
      {renderOverlay(pageLoading || isMutating)}
      {renderError(pageError)}
      {isAuthenticated && !pageError && !pageLoading && renderUnauthorized(isAuthorized)}
      {isAuthenticated &&
        isAuthorized &&
        !pageError &&
        pageLoadedOrRefetching &&
        renderContent()}
    </div>
  );
};

export default QuarantineList;
