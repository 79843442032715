import 'whatwg-fetch';
import { createRoot } from 'react-dom/client';
import { MsalProvider } from '@azure/msal-react';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import apolloClient from './lib/apollo_client';
import msalInstance from './lib/msal_instance';

import App from './app';

Sentry.init({
  dsn: process.env.CLIENT_SENTRY_DSN,
  environment: process.env.CLIENT_SENTRY_ENV,
});

// https://www.apollographql.com/docs/react/v2/data/local-state/

const rootEl = document.getElementById('root');
const root = createRoot(rootEl);

const initApp = async (Container) => {
  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <MsalProvider instance={msalInstance}>
          <Container />
        </MsalProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
};

initApp(App).then((app) => root.render(app));
