import React, { useState, useEffect, useCallback } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrosoft } from '@fortawesome/free-brands-svg-icons';

import Logo from '../images/smx-color-horiz.svg';
import { loginRequest } from '../lib/auth_config';

const LandingNew = () => {
  const [delayExpired, setDelayExpired] = useState(false);
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const handleLogin = useCallback(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      try {
        await instance.handleRedirectPromise();
        await instance.loginRedirect(loginRequest);
      } catch (err) {
        console.log('Login Error');
        console.log(err);
      }
    }
  }, [isAuthenticated, inProgress, instance]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDelayExpired(true);
    }, 200);
    return () => clearTimeout(timer);
  }, []);

  const renderContent = () => {
    return (
      <Card style={{ maxWidth: '235px' }}>
        <Card.Body>
          <div className="text-center">
            <Logo />
          </div>
          <div className="text-center mt-4">
            <Button type="submit" variant="primary" size="lg" onClick={handleLogin}>
              <FontAwesomeIcon icon={faMicrosoft} className="me-2" />
              Login with Microsoft
            </Button>
          </div>
        </Card.Body>
      </Card>
    );
  };

  return <div>{delayExpired && renderContent()}</div>;
};

export default LandingNew;
