/* eslint-disable  react/jsx-props-no-spreading */
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Card, Col, Container, Row, Button, ListGroup, Tab, Nav } from 'react-bootstrap';
import { useIsAuthenticated } from '@azure/msal-react';
import { useQuery, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import { useNavigate, useParams } from 'react-router-dom';
import { Letter } from 'react-letter';
import { AlertCircle } from 'react-feather';

import get from 'lodash.get';
import includes from 'lodash.includes';

import {
  renderUnauthenticated,
  renderUnauthorized,
  renderOverlay,
  renderError,
  releaseMessages,
} from '../components/render_helpers';
import Confirm from '../components/confirm';
import pageQuarantineShowQuery from '../queries/page_quarantine_show_query';
import quarantineViewQuery from '../queries/quarantine_view_query';
import { NotificationActionTypes, DisplayQuarantineTypes } from '../lib/constants';
import quarantineReleaseMutation from '../mutations/quarantine_release_mutation';
import optimistic from '../lib/optimistic';
import googleAnalytics from '../lib/google_analytics';
import applicationInsights from '../lib/application_insights';
import quarantineReleaseUpdateFunction from '../update_functions/quarantine_release_update_function';
import { toastSuccess, toastError } from '../lib/toast';
import useSettings from '../hooks/use_settings';

const QuarantineShow = () => {
  const navigate = useNavigate();
  const params = useParams();
  const isAuthenticated = useIsAuthenticated();
  const [isAuthorized, setIsAuthorized] = useState(false);
  const { isMutating, setIsMutating } = useSettings();
  const [quarantineItem, setQuarantineItem] = useState(null);
  const [quarantineView, setQuarantineView] = useState(null);
  const [quarantineRelease] = useMutation(quarantineReleaseMutation);
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
  } = useQuery(pageQuarantineShowQuery);

  const {
    data: lazyData,
    loading: lazyLoading,
    error: lazyError,
  } = useQuery(quarantineViewQuery, {
    variables: {
      quarantineId: params.id,
      recipient: get(quarantineItem, 'recipient'),
    },
    skip: !get(quarantineItem, 'recipient'),
  });

  useEffect(() => {
    setIsAuthorized(!!get(pageData, 'settings.quarantineSelfRelease'));
    const item = get(pageData, 'quarantineList', []).find((qi) => qi.id === params.id);
    if (item) {
      setQuarantineItem(item);
    } else {
      setQuarantineView({ id: params.id, email: {} });
    }
  }, [pageData, params.id]);

  useEffect(() => {
    setQuarantineView(get(lazyData, 'quarantineView'));
  }, [lazyData]);

  const handleRelease = async () => {
    const mutationData = {
      variables: { input: { id: params.id, recipient: quarantineItem.recipient } },
      context: {
        recordType: 'QuarantineType',
        recordId: params.id,
        mutationType: 'UPDATE',
      },
      update: quarantineReleaseUpdateFunction,
    };
    mutationData.optimisticResponse = optimistic('quarantineRelease', mutationData, {
      released: false,
    });
    try {
      setIsMutating(true);
      await quarantineRelease(mutationData);
      googleAnalytics.sendEvent({
        category: 'User',
        action: 'ShowReleaseSuccess',
      });
      applicationInsights.sendEvent({
        name: 'ShowReleaseSuccess',
      });
      toastSuccess('Message released');
      await setIsMutating(false);
      navigate('/search/quarantine');
    } catch (err) {
      console.log(err.toString());
      googleAnalytics.sendEvent({
        category: 'User',
        action: 'ShowReleaseFailure',
      });
      applicationInsights.sendEvent({
        name: 'ShowReleaseFailure',
      });
      toastError('Message release failed');
      setIsMutating(false);
    }
  };

  const renderContent = () => {
    const actions = get(pageData, 'settings.quarantineSelfRelease.actions', []);
    const {
      id,
      released,
      quarantineType,
      quarantineRuleName,
      sender,
      expiresAtDateTimeUtc,
    } = quarantineItem;
    const {
      email: { to, from, subject, date, attachments, text, html },
    } = quarantineView;
    const filteredAttachments = attachments.filter(
      ({ contentType }) => !/^image\//g.test(contentType)
    );

    return (
      <>
        <Helmet title="Quarantined Item" />
        <Container fluid className="p-0">
          <Row>
            <Col sm={12}>
              <div className="float-none">
                <div className="float-start">
                  <h1 className="h3 mb-3">{to ? subject : 'Quarantined Item'}</h1>
                </div>
                <div className="float-end">
                  <Button
                    className="me-2"
                    variant="secondary"
                    onClick={() => navigate('/search/quarantine')}
                  >
                    Back
                  </Button>

                  {to &&
                    includes(actions, NotificationActionTypes.REVIEW) &&
                    includes(actions, NotificationActionTypes.RELEASE) && (
                      <Confirm
                        onConfirm={handleRelease}
                        title="Release Message"
                        body={releaseMessages(released)}
                        confirmText="Confirm"
                      >
                        <Button className="me-2" variant="primary">
                          Release
                        </Button>
                      </Confirm>
                    )}
                </div>
              </div>
            </Col>
          </Row>
          {to ? (
            <Row>
              <Col>
                <div className="tab">
                  <Tab.Container id="left-tabs-example" defaultActiveKey="basic">
                    <Nav variant="tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="basic">Basic</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="attachments">
                          {filteredAttachments?.length > 0 && (
                            <AlertCircle
                              color="red"
                              className="me-1"
                              style={{ verticalAlign: 'top' }}
                            />
                          )}
                          Attachments
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="detail">Detail</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content>
                      <Tab.Pane eventKey="basic">
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <span style={{ display: 'inline-block', width: '100px' }}>
                              DATE:
                            </span>
                            {DateTime.fromISO(date)
                              .setLocale('en-nz')
                              .toLocaleString(DateTime.DATETIME_FULL)}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <span style={{ display: 'inline-block', width: '100px' }}>
                              FROM:
                            </span>
                            {from}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <span style={{ display: 'inline-block', width: '100px' }}>
                              TO:
                            </span>
                            {to}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <span style={{ display: 'inline-block', width: '100px' }}>
                              SUBJECT:
                            </span>
                            {subject}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <Letter
                              rewriteExternalLinks={() => 'about:blank'}
                              {...(html && { html })}
                              {...(text && { text })}
                            />
                          </ListGroup.Item>
                        </ListGroup>
                      </Tab.Pane>
                      <Tab.Pane eventKey="attachments">
                        {filteredAttachments?.length > 0 ? (
                          <ListGroup variant="flush">
                            {filteredAttachments.map(({ filename, contentType }) => (
                              <ListGroup.Item
                                key={filename}
                              >{`${filename} (${contentType})`}</ListGroup.Item>
                            ))}
                          </ListGroup>
                        ) : (
                          <p>No attachments</p>
                        )}
                      </Tab.Pane>
                      <Tab.Pane eventKey="detail">
                        <ListGroup variant="flush">
                          <ListGroup.Item>
                            <span style={{ display: 'inline-block', width: '150px' }}>
                              ENVELOPE SENDER:
                            </span>
                            {sender}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <span style={{ display: 'inline-block', width: '150px' }}>
                              SMX MESSAGE ID:
                            </span>
                            {id}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <span style={{ display: 'inline-block', width: '150px' }}>
                              RELEASED:
                            </span>
                            {released ? 'Yes' : 'No'}
                          </ListGroup.Item>
                          <ListGroup.Item>
                            <span style={{ display: 'inline-block', width: '150px' }}>
                              QUARANTINE TYPE:
                            </span>
                            {DisplayQuarantineTypes[quarantineType]}
                          </ListGroup.Item>
                          {quarantineRuleName && (
                            <ListGroup.Item>
                              <span style={{ display: 'inline-block', width: '150px' }}>
                                RULE NAME:
                              </span>
                              {quarantineRuleName}
                            </ListGroup.Item>
                          )}
                          <ListGroup.Item>
                            <span style={{ display: 'inline-block', width: '150px' }}>
                              EXPIRY DATE:
                            </span>
                            {DateTime.fromISO(expiresAtDateTimeUtc)
                              .setLocale('en-nz')
                              .toLocaleString(DateTime.DATE_SHORT)}
                          </ListGroup.Item>
                        </ListGroup>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </Col>
            </Row>
          ) : (
            <Row>
              <Col>
                <Card>
                  <Card.Header className="pb-0">
                    <Card.Title tag="h5" className="mb-0">
                      Not found
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    We were unable to retrieve the message for review
                    <ul>
                      <li>It may already have been released</li>
                      <li>It may have expired</li>
                      <li>This message id may not relate to your email address</li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </>
    );
  };

  return (
    <div>
      {renderUnauthenticated(isAuthenticated)}
      {renderOverlay(pageLoading || lazyLoading || isMutating)}
      {renderError(pageError || lazyError)}
      {isAuthenticated &&
        !pageLoading &&
        !pageError &&
        !lazyLoading &&
        !lazyError &&
        renderUnauthorized(isAuthorized)}
      {isAuthenticated &&
        isAuthorized &&
        !pageLoading &&
        !pageError &&
        !lazyLoading &&
        !lazyError &&
        (!quarantineItem ||
          (quarantineItem && !quarantineView) ||
          (quarantineItem && quarantineView && !quarantineView.email)) && (
          <p>
            The requested message cannot be found, quarantined messages are only retained
            for 30 days
          </p>
        )}
      {isAuthenticated &&
        isAuthorized &&
        !pageLoading &&
        !pageError &&
        !lazyLoading &&
        !lazyError &&
        quarantineItem &&
        quarantineView &&
        quarantineView.email &&
        renderContent()}
    </div>
  );
};

export default QuarantineShow;
