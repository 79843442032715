import PerfectScrollbar from 'react-perfect-scrollbar';

import useSettings from '../../hooks/use_settings';
import SidebarFooter from './sidebar/sidebar_footer';
import SidebarNav from './sidebar/sidebar_nav';
// import Logo from '../../images/smx_logo.svg';
import Logo from '../../images/smx-white-horiz.svg';

import dashboardItems from '../../lib/dashboard_items';

const Sidebar = ({ showFooter = false }) => {
  const { isOpen } = useSettings();

  return (
    <nav className={`sidebar ${!isOpen ? 'collapsed' : ''}`}>
      <div className="sidebar-content">
        <PerfectScrollbar>
          <a className="sidebar-brand" href="/">
            <Logo />
            <p className="mt-1">Intelligence Center</p>
          </a>
          <SidebarNav items={dashboardItems} />
          {!!showFooter && <SidebarFooter />}
        </PerfectScrollbar>
      </div>
    </nav>
  );
};

export default Sidebar;
