import Logo from '../../images/smx-color-horiz.svg';

const Error = () => (
  <div className="text-center mt-4">
    <Logo style={{ width: '60%' }} />
    <h2 className="display-1 fw-bold">SMX Intelligence Center</h2>
    <p className="h2 fw-normal mt-3 mb-4">Something went wrong</p>
    <p className="h2 fw-normal mt-3 mb-4">
      An error has been logged. Please try again later
    </p>
  </div>
);

export default Error;
