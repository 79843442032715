import { protectedResources } from '../lib/auth_config';

const DisplayProfileData = (props) => {
  const tableRows = Object.entries(props.profileData).map(([name, value]) => {
    return (
      <tr key={name}>
        <td>
          <b>{name}: </b>
        </td>
        <td>
          <i>{value}</i>
        </td>
      </tr>
    );
  });

  return (
    <>
      <div className="data-area-div">
        <p>
          Calling <strong>Microsoft Graph API</strong>...
        </p>
        <ul>
          <li>
            <strong>resource:</strong> <mark>User</mark> object
          </li>
          <li>
            <strong>endpoint:</strong> <mark>{protectedResources.GraphMe.endpoint}</mark>
          </li>
          <li>
            <strong>scope:</strong>{' '}
            <mark>{protectedResources.GraphMe.scopes.join(', ')}</mark>
          </li>
        </ul>
        <p>
          Contents of the <strong>response</strong> is below:
        </p>
      </div>
      <div className="data-area-div">
        <table>
          <thead />
          <tbody>{tableRows}</tbody>
        </table>
      </div>
    </>
  );
};

const DisplayFunctionData = (props) => {
  const tableRows = Object.entries(props.profileData).map(([name, value]) => {
    return (
      <tr key={name}>
        <td>
          <b>{name}: </b>
        </td>
        <td>
          <i>{Array.isArray(value) ? value.join(', ') : value}</i>
        </td>
      </tr>
    );
  });

  return (
    <>
      <div className="data-area-div">
        <p>
          Calling{' '}
          <strong>
            protected Azure Function API (which in turn calls Microsoft Graph)
          </strong>
          ...
        </p>
        <ul>
          <li>
            <strong>endpoint:</strong> <mark>{protectedResources.GetUser.endpoint}</mark>
          </li>
          <li>
            <strong>scope:</strong>{' '}
            <mark>{protectedResources.GetUser.scopes.join(', ')}</mark>
          </li>
        </ul>
        <p>
          Contents of the <strong>response</strong> is below:
        </p>
      </div>
      <div className="data-area-div">
        <table>
          <thead />
          <tbody>{tableRows}</tbody>
        </table>
      </div>
    </>
  );
};

export { DisplayFunctionData, DisplayProfileData };
