import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

import { loginRequest } from '../lib/auth_config';
import DashboardLayout from './dashboard_layout';
import LoaderComponent from '../components/loader_component';
import ErrorComponent from '../components/error_component';

const AuthenticatedDashboardLayout = () => (
  <MsalAuthenticationTemplate
    interactionType={InteractionType.Redirect}
    authenticationRequest={loginRequest}
    errorComponent={ErrorComponent}
    loadingComponent={LoaderComponent}
  >
    <DashboardLayout />
  </MsalAuthenticationTemplate>
);

export default AuthenticatedDashboardLayout;
