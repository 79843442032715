const NotificationActionTypes = {
  RELEASE: 'RELEASE',
  REVIEW: 'REVIEW',
  UNREVIEWED_RELEASE: 'UNREVIEWED_RELEASE',
};

const DisplayQuarantineTypes = {
  SPAM: 'Spam',
  DLP: 'DLP',
  GRAY: 'Gray',
};

export { NotificationActionTypes, DisplayQuarantineTypes };
