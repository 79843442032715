import { Navigate, useRoutes, Outlet } from 'react-router-dom';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';

import LandingLayout from '../layouts/landing_layout';
import DashboardLayout from '../layouts/dashboard_layout';
import AuthenticatedDashboardLayout from '../layouts/authenticated_dashboard_layout';

// import Landing from '../pages/landing';
import LandingNew from '../pages/landing_new';
// import Home from '../pages/home';
import Profile from '../pages/profile';
import Function from '../pages/function';
import Page404 from '../pages/page_404';
import QuarantineList from '../pages/quarantine_list';
import QuarantineShow from '../pages/quarantine_show';

const AppRoutes = () =>
  useRoutes([
    {
      path: '/',
      element: <Outlet />,
      children: [
        {
          path: '',
          element: (
            <>
              <UnauthenticatedTemplate>
                <LandingLayout>
                  <LandingNew />
                </LandingLayout>
              </UnauthenticatedTemplate>
              <AuthenticatedTemplate>
                <DashboardLayout>
                  <Navigate to="/search/quarantine" />,
                </DashboardLayout>
              </AuthenticatedTemplate>
            </>
          ),
        },
      ],
    },
    {
      path: '/404',
      element: <Page404 />,
    },
    {
      path: '/search',
      element: <AuthenticatedDashboardLayout />,
      children: [
        {
          path: 'quarantine',
          children: [
            {
              path: ':id',
              element: <QuarantineShow />,
            },
            {
              path: '',
              element: <QuarantineList />,
            },
          ],
        },
      ],
    },
    {
      path: '/develop',
      element: <AuthenticatedDashboardLayout />,
      children: [
        {
          path: 'function',
          element: <Function />,
        },
      ],
    },
    {
      path: '/auth',
      element: <AuthenticatedDashboardLayout />,
      children: [
        {
          path: 'profile',
          children: [
            {
              path: '',
              element: <Profile />,
            },
          ],
        },
      ],
    },
    {
      path: '/*',
      element: <Navigate to="/404" />,
    },
  ]);

export default AppRoutes;
