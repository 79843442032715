import { useEffect } from 'react';
import useLocalStorage from './use_local_storage';

function useSettingsState(key, initialValue) {
  const [value, setValue] = useLocalStorage(key, initialValue);

  useEffect(() => {
    // Set data attribute on body element
    document.body.dataset[key] = value;
  }, [value, key]);

  return [value, setValue];
}

export default useSettingsState;
