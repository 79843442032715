import { ApolloClient, ApolloLink, createHttpLink, InMemoryCache } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import * as Sentry from '@sentry/react';

import getAccessToken from './get_access_token';

import { protectedResources } from './auth_config';

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((error) => {
      if (process.env.NODE_ENV === 'development') {
        console.log(`[GraphQL error]: ${JSON.stringify(error, undefined, 2)}`);
      } else {
        Sentry.captureMessage('Graphql error', {
          level: 'info',
          extra: { error },
        });
      }
    });
  }
  if (networkError) {
    if (process.env.NODE_ENV === 'development') {
      console.log(
        `[Graphql network error]: ${JSON.stringify(networkError, undefined, 2)}`
      );
    } else {
      Sentry.captureMessage('Graphql network error', {
        level: 'info',
        extra: { networkError },
      });
    }
  }
});

const httpLink = createHttpLink({
  uri: protectedResources.GetGraphql.endpoint,
});

const authLink = setContext((_, { headers }) => {
  return getAccessToken(protectedResources.GetGraphql).then((accessToken) => {
    return {
      headers: {
        ...headers,
        'x-smx-authorization': accessToken ? `Bearer ${accessToken}` : '',
      },
    };
  });
});

const link = ApolloLink.from([errorLink, authLink, httpLink]);

const cache = new InMemoryCache({
  addTypename: true,
});

const apolloClient = new ApolloClient({
  link,
  cache,
  // // eslint-disable-next-line no-underscore-dangle
  // cache: cache.restore(window.__APOLLO_STATE__ || {}),
  // connectToDevTools: true, // allows in production
  // shouldBatch: true,
  // local state
  // https://blog.apollographql.com/announcing-apollo-client-2-5-c12230cabbb7
  // resolvers: { ... },
  // typeDefs: { ... },
});

export default apolloClient;
