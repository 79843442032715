import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const reactPlugin = new ReactPlugin();
const applicationInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.APPLICATION_INSIGHTS_CONNECTION_STRING,
    extensions: [reactPlugin],
    enableAutoRouteTracking: true,
  },
});

function init() {
  applicationInsights.loadAppInsights();
}

function sendEvent(payload) {
  applicationInsights.trackEvent(payload);
}

export default {
  init,
  sendEvent,
};
