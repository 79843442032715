import React from 'react';
// import { useLocation } from 'react-router-dom';

import applicationInsights from '../lib/application_insights';

export default function useApplicationInsights() {
  // const location = useLocation();

  React.useEffect(() => {
    applicationInsights.init();
  }, []);

  // React.useEffect(() => {
  //   const currentPath = location.pathname + location.search;
  //   applicationInsights.sendPageview(currentPath);
  // }, [location]);
}
