import { HelmetProvider, Helmet } from 'react-helmet-async';
import { ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';

import { SettingsProvider } from './contexts/settings_context';
import ErrorBoundaryLayout from './layouts/error_boundary_layout';

import AppRoutes from './lib/routes';
import useGoogleAnalytics from './hooks/use_google_analytics';
import useApplicationInsights from './hooks/use_application_insights';

// appstack stylesheet (includes bootstrap)
import './styles/light.css';
// local overrides
import './styles/local.css';
// toasts
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  useGoogleAnalytics();
  useApplicationInsights();

  const renderErrorBoundary = ({ error, componentStack }) => (
    <ErrorBoundaryLayout error={error} componentStack={componentStack} />
  );

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | SMX Intelligence Center"
        defaultTitle="SMX Intelligence Center"
      />
      <Sentry.ErrorBoundary fallback={renderErrorBoundary}>
        <ToastContainer />
        <SettingsProvider>
          <AppRoutes />
        </SettingsProvider>
      </Sentry.ErrorBoundary>
    </HelmetProvider>
  );
};

export default App;
