import { Helmet } from 'react-helmet-async';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useMsal, useIsAuthenticated } from '@azure/msal-react';
import jwtDecode from 'jwt-decode';

import { protectedResources } from '../lib/auth_config';
import { DisplayFunctionData } from '../components/display_data';
import getAccessToken from '../lib/get_access_token';

const Function = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [user, setUser] = useState(null);
  const [localToken, setLocalToken] = useState(null);
  const [remoteToken, setRemoteToken] = useState(null);

  useEffect(() => {
    async function getData() {
      try {
        const accessToken = await getAccessToken(protectedResources.GetUser);
        if (accessToken) {
          let decodedToken;
          try {
            decodedToken = jwtDecode(accessToken);
            setLocalToken(decodedToken);
          } catch (error) {
            console.log('Local token cannot be decoded');
            console.log(error);
          }
          const graphResponse = await axios({
            method: 'get',
            url: protectedResources.GetUser.endpoint,
            headers: {
              'x-smx-authorization': `Bearer ${accessToken}`,
            },
          });
          setUser(graphResponse.data.user);
          setRemoteToken(graphResponse.data.decodedToken);
        }
      } catch (err) {
        console.log(err.toString());
      }
    }
    if (isAuthenticated && inProgress === 'none') {
      getData();
    }
  }, [isAuthenticated, inProgress]);

  return (
    <>
      <Helmet title="Function" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">Function</h1>
        <Row>
          <Col>
            <Card>
              <Card.Header>
                <Card.Title tag="h5" className="mb-0">
                  Function
                </Card.Title>
              </Card.Header>
              <Card.Body>
                <p>user</p>
                {user && <DisplayFunctionData profileData={user} />}
                <p>local token</p>
                {localToken && <pre>{JSON.stringify(localToken, undefined, 2)}</pre>}
                <p>remote token</p>
                {remoteToken && <pre>{JSON.stringify(remoteToken, undefined, 2)}</pre>}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Function;
