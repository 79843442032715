import merge from 'lodash.merge';

import quarantineFragment from '../fragments/quarantine_type_base_fragment';
import quarantineQuery from '../queries/quarantine_query';
import cleanApolloCache from '../lib/clean_apollo_cache';

const quarantineReleaseUpdateFunction = (cache, { data: { quarantineRelease } }) => {
  let currentData;
  try {
    currentData = cache.readQuery({
      query: quarantineQuery,
      variables: { id: quarantineRelease.id },
    });
    if (!currentData) {
      currentData = cache.readFragment({
        id: `QuarantineType:${quarantineRelease.id}`,
        fragment: quarantineFragment,
        fragmentName: 'QuarantineTypeBase',
      });
    }
  } catch (err) {
    currentData = { quarantine: {} };
  }
  currentData = currentData.quarantine || currentData;
  const data = { quarantine: merge({}, currentData, quarantineRelease) };
  cache.writeQuery({
    query: quarantineQuery,
    variables: { id: quarantineRelease.id.toString() },
    data,
  });
  cleanApolloCache(cache);
};

export default quarantineReleaseUpdateFunction;
