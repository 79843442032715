import SidebarNavList from './sidebar_nav_list';

const SidebarNavSection = (props) => {
  const { title, pages } = props;

  return (
    <>
      {title && <li className="sidebar-header">{title}</li>}
      <SidebarNavList pages={pages} depth={0} />
    </>
  );
};

export default SidebarNavSection;
