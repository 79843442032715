import LoaderComponent from './loader_component';
import ErrorComponent from './error_component';

export const renderOverlay = (loading = false, settingsMutating = false) => {
  if (loading || settingsMutating) {
    return <LoaderComponent />;
  }
  return undefined;
};

export const renderUnauthenticated = (authenticated = false) => {
  if (!authenticated) {
    return <p>logging out...</p>;
  }
  return undefined;
};

export const renderUnauthorized = (authorized = false) => {
  if (!authorized) {
    return <p>You are not authorized to view this page</p>;
  }
  return undefined;
};

export const renderError = (error) => {
  if (error) {
    return <ErrorComponent error={error} />;
  }
  return undefined;
};

export const releaseMessages = (released) => {
  const messages = ['Please note, it may take several minutes to receive this message.'];
  if (released) {
    messages.unshift(
      'This message has already been released, are you sure you want to release again?'
    );
  } else {
    messages.unshift('Are you sure you want to release this message?');
  }
  return messages;
};
