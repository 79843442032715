import { Search, Code, Users, PieChart, File } from 'react-feather';
// import qs from 'qs';

// const params = qs.stringify({
//   client_id: process.env.CLIENT_AAD_APP_CLIENT_ID,
//   // It must exactly match one of the redirect URIs that you registered
//   // in the app registration portal. So it can't be dynamic.
//   // see https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-admin-consent
//   // eslint-disable-next-line camelcase
//   redirect_uri: process.env.CLIENT_AAD_APP_REDIRECT_URI,
//   // state: window.location.hash.toString(),
//   scope: 'https://graph.microsoft.com/.default',
// });

// const integrationHref = `https://login.microsoftonline.com/organizations/v2.0/adminconsent?${params}`;
// console.log(integrationHref);
const searchSection = [
  {
    href: '/search',
    icon: Search,
    title: 'Search',
    children: [
      // {
      //   href: '/search/message',
      //   title: 'Message',
      //   disabled: true,
      // },
      {
        href: '/search/quarantine',
        title: 'Quarantine',
      },
    ],
  },
  // {
  //   href: '/dashboards',
  //   icon: PieChart,
  //   title: 'Dashboards',
  //   disabled: true,
  //   children: [
  //     {
  //       href: '/dashboards/mailflow',
  //       title: 'Mailflow',
  //     },
  //   ],
  // },
  // {
  //   href: '/reports',
  //   icon: File,
  //   title: 'Reports',
  //   disabled: true,
  //   children: [
  //     {
  //       href: '/reports/mailflow',
  //       title: 'Mailflow',
  //     },
  //   ],
  // },
  // {
  //   href: '/develop',
  //   icon: Code,
  //   title: 'Develop',
  //   children: [
  //     {
  //       href: '/develop/function',
  //       title: 'Tokens',
  //     },
  //     { href: integrationHref, title: 'Admin Approve' },
  //   ],
  // },
  // {
  //   href: '/auth',
  //   icon: Users,
  //   title: 'Auth',
  //   children: [
  //     {
  //       href: '/auth/profile',
  //       title: 'Microsoft Profile',
  //     },
  //   ],
  // },
];

const navItems = [
  {
    title: 'Search',
    showTitle: false,
    pages: searchSection,
  },
];

export default navItems;
