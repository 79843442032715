import reduce from 'lodash.reduce';
import omit from 'lodash.omit';

// all keys needed to write a frgament.  Optimistic responses that don't define all these keys
// will fail, often silently or breaking queries for reaons that are hard to debug.
// Basically whatever fragment you're using in the writeFragment you need every one of those keys
// I need to revisit it this is idiotic
const requiredForType = {
  QuarantineType: [
    'subject',
    'sender',
    'recipient',
    'partyRef',
    'createdAtDateTimeUtc',
    'messageMarkerDateTimeUtc',
    'quarantineType',
    'quarantineRuleName',
    'released',
    'fromHeader',
  ],
};

const optimistic = (operationName, mutationData, extraData = {}, omitKeys = []) => {
  const response = {
    __typename: 'Mutation',
    [operationName]: {
      __typename: mutationData.context.recordType,
      ...(mutationData.context.mutationType === 'CREATE' &&
        reduce(
          requiredForType[mutationData.context.recordType] || [],
          (r, k) => ({ ...r, [k]: null }),
          {}
        )),
      ...(mutationData.context.recordId && { id: mutationData.context.recordId }),
      ...(mutationData.variables.input && omit(mutationData.variables.input, omitKeys)),
      ...extraData,
    },
  };
  return response;
};

export default optimistic;
