// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../images/login-background-low.jpeg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sidebar, .sidebar-content {\n  background: #303CBF;\n}\n\n.sidebar-brand {\n  text-align: center;\n}\n\n.sidebar-brand svg {\n  height: 75%;\n  width: 75%;\n}\n\n.sidebar-dropdown .sidebar-item.active > .sidebar-link:not([data-bs-toggle]), .sidebar-dropdown .sidebar-item > .sidebar-link.active {\n  font-weight: 400;\n  color: #e9ecef;\n}\n\n.smx-landing-background {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n}\n", "",{"version":3,"sources":["webpack://./src/styles/local.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,yDAA4D;EAC5D,sBAAsB;EACtB,4BAA4B;EAC5B,2BAA2B;AAC7B","sourcesContent":[".sidebar, .sidebar-content {\n  background: #303CBF;\n}\n\n.sidebar-brand {\n  text-align: center;\n}\n\n.sidebar-brand svg {\n  height: 75%;\n  width: 75%;\n}\n\n.sidebar-dropdown .sidebar-item.active > .sidebar-link:not([data-bs-toggle]), .sidebar-dropdown .sidebar-item > .sidebar-link.active {\n  font-weight: 400;\n  color: #e9ecef;\n}\n\n.smx-landing-background {\n  background-image: url(\"../images/login-background-low.jpeg\");\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
