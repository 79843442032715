import Logo from '../../images/smx-color-horiz.svg';

const Unauthorized = () => (
  <div className="text-center mt-4">
    <Logo style={{ width: '60%' }} />
    <h2 className="display-1 fw-bold">SMX Intelligence Center</h2>
    <p className="h2 fw-normal mt-3 mb-4">
      If you think you should be able to access this service, please contact your
      administrator
    </p>
  </div>
);

export default Unauthorized;
