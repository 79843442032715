import { InteractionRequiredAuthError } from '@azure/msal-browser';
import get from 'lodash.get';

import msalInstance from './msal_instance';

const getAccessToken = async (protectedResource) => {
  const accounts = msalInstance.getAllAccounts();
  const account = get(accounts, '0', {});
  const scopes = get(protectedResource, 'scopes', []);
  try {
    const silentResponse = await msalInstance.acquireTokenSilent({
      scopes,
      account,
    });
    const { accessToken } = silentResponse;
    // to test
    // make sure you set navigateToLoginRequestUrl: false
    // throw new InteractionRequiredAuthError('silent error');
    return accessToken;
  } catch (silentErr) {
    if (silentErr instanceof InteractionRequiredAuthError) {
      try {
        const redirectResponse = msalInstance.acquireTokenRedirect({ scopes, account });
        const { accessToken } = redirectResponse;
        return accessToken;
      } catch (redirectErr) {
        console.log('redirect authorization token error');
        console.log(redirectErr);
        return undefined;
      }
    }
    console.log('silent authorization token error');
    console.log(silentErr);
    return undefined;
  }
};

export default getAccessToken;
