import { Outlet } from 'react-router-dom';

import Main from './components/main';

const LandingLayout = ({ children }) => {
  return (
    <Main className="d-flex w-100 justify-content-center align-items-center smx-landing-background">
      {children}
      <Outlet />
    </Main>
  );
};

export default LandingLayout;
