/* eslint-disable import/no-cycle */
import { matchPath } from 'react-router-dom';

import SidebarNavListItem from './sidebar_nav_list_item';
import SidebarNavList from './sidebar_nav_list';

const reduceChildRoutes = (props) => {
  const { items, page, depth, currentRoute } = props;

  if (page.children) {
    let open = false;

    if (page.href) {
      if (page.href === '/search' && currentRoute === '/') {
        open = true;
      } else {
        open = !!matchPath(
          {
            path: page.href,
            end: false,
          },
          currentRoute
        );
      }
    }

    items.push(
      <SidebarNavListItem
        depth={depth}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        open={!!open}
        title={page.title}
        href={page.href}
        disabled={page.disabled}
      >
        <SidebarNavList depth={depth + 1} pages={page.children} />
      </SidebarNavListItem>
    );
  } else {
    items.push(
      <SidebarNavListItem
        depth={depth}
        href={page.href}
        icon={page.icon}
        key={page.title}
        badge={page.badge}
        title={page.title}
        disabled={page.disabled}
      />
    );
  }

  return items;
};

export default reduceChildRoutes;
