import { Helmet } from 'react-helmet-async';
import { Col, Container, Row, Card } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useIsAuthenticated, useMsal, useAccount } from '@azure/msal-react';
// import jwtDecode from 'jwt-decode';
import compact from 'lodash.compact';
import get from 'lodash.get';
import uniq from 'lodash.uniq';
import last from 'lodash.last';

import { protectedResources } from '../lib/auth_config';
import getAccessToken from '../lib/get_access_token';

const Profile = () => {
  const { inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [graphMeData, setGraphMeData] = useState(null);
  // const [localToken, setLocalToken] = useState(null);
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  useEffect(() => {
    async function getData() {
      try {
        // const accessToken = await getAccessToken(protectedResources.GraphMe);
        const accessToken = await getAccessToken(protectedResources.GetUser);
        if (accessToken) {
          // let decodedToken;
          // try {
          //   decodedToken = jwtDecode(accessToken);
          //   setLocalToken(decodedToken);
          // } catch (error) {
          //   console.log('Local token cannot be decoded');
          //   console.log(error);
          // }
          // const [graphMeResponse, graphMeMemberOfGroupResponse] = await Promise.all([
          //   axios({
          //     method: 'get',
          //     url: `${protectedResources.GraphMe.endpoint}?$select=id,displayName,givenName,surname,mail,otherMails,proxyAddresses`,
          //     headers: {
          //       Authorization: `Bearer ${accessToken}`,
          //     },
          //   }),
          //   axios({
          //     method: 'get',
          //     url: `${protectedResources.GraphMe.endpoint}/memberOf/microsoft.graph.group?&$select=id,displayName,mailEnabled,mail`,
          //     headers: {
          //       Authorization: `Bearer ${accessToken}`,
          //     },
          //   }),
          // ]);
          // setGraphMeData({
          //   ...graphMeResponse.data,
          //   listMemberships: get(graphMeMemberOfGroupResponse, 'data.value', []).filter(
          //     (lm) => lm.mailEnabled
          //   ),
          // });
          const graphResponse = await axios({
            method: 'get',
            url: protectedResources.GetUser.endpoint,
            headers: {
              'x-smx-authorization': `Bearer ${accessToken}`,
            },
          });
          setGraphMeData(graphResponse.data.user);
        }
      } catch (err) {
        console.log(err.toString());
      }
    }
    if (isAuthenticated && inProgress === 'none') {
      getData();
    }
  }, [isAuthenticated, inProgress]);

  const renderGraphMeDataValue = (name, value) => {
    if (value) {
      if (Array.isArray(value)) {
        if (value.length > 0) {
          if (typeof value[0] === 'string') {
            return (
              <dd className="col-sm-9">
                {value.map((v) => (
                  <span key={v}>
                    {v}
                    <br />
                  </span>
                ))}
              </dd>
            );
          }
          if (typeof value[0] === 'object') {
            if (name === 'mailEnabledGroups') {
              return (
                <dd className="col-sm-9">
                  {value.map((v) => (
                    <span key={v.mail}>
                      {v.mail}
                      <br />
                    </span>
                  ))}
                </dd>
              );
            }
            return (
              <dd className="col-sm-9">
                {value.map((v) => (
                  <span key={v.id}>
                    {v.id}
                    <br />
                  </span>
                ))}
              </dd>
            );
          }
        }
        return <dd className="col-sm-9">-</dd>;
      }
      return <dd className="col-sm-9">{value}</dd>;
    }
    return <dd className="col-sm-9">-</dd>;
  };

  const renderGraphMeData = () => {
    const {
      givenName,
      surname,
      mail,
      otherMails = [],
      proxyAddresses = [],
      mailEnabledGroups = [],
    } = graphMeData;
    const proxyRecipients = proxyAddresses.map((r) => last(r.split(':')));
    const recipients = uniq(compact([mail, ...otherMails, ...proxyRecipients]));
    return (
      <dl className="row">
        <dt className="col-sm-3 text-truncate">First Name</dt>
        {renderGraphMeDataValue('givenName', givenName)}
        <dt className="col-sm-3 text-truncate">Last Name</dt>
        {renderGraphMeDataValue('surname', surname)}
        <dt className="col-sm-3 text-truncate">Known Email Addresses</dt>
        {renderGraphMeDataValue('recipients', recipients)}
        <dt className="col-sm-3 text-truncate">List Memberships</dt>
        {renderGraphMeDataValue('mailEnabledGroups', mailEnabledGroups)}
      </dl>
    );
  };

  return (
    <>
      <Helmet title="Profile" />
      <Container fluid className="p-0">
        <h1 className="h3 mb-3">{`Profile for ${get(account, 'name', 'user')}`}</h1>
        <Row>
          <Col>
            <Card>
              <Card.Body>{graphMeData && renderGraphMeData()}</Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
