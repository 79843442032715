import { useCallback } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal, useAccount } from '@azure/msal-react';

import NavbarUser from './navbar/navbar_user';
import useSettings from '../../hooks/use_settings';
import { logoutRequest } from '../../lib/auth_config';

const NavbarComponent = () => {
  const { isOpen, setIsOpen } = useSettings();
  const { instance, inProgress, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const handleLogout = useCallback(
    async (e) => {
      e.preventDefault();
      if (inProgress === InteractionStatus.None) {
        try {
          await instance.handleRedirectPromise();
          await instance.logoutRedirect(logoutRequest);
        } catch (err) {
          console.log('Logout Error');
          console.log(err);
        }
      }
    },
    [inProgress, instance]
  );

  return (
    <Navbar variant="light" expand className="navbar-bg">
      <span
        className="sidebar-toggle d-flex"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <i className="hamburger align-self-center" />
      </span>

      <Navbar.Collapse>
        <Nav className="navbar-align">
          <NavbarUser handleLogout={handleLogout} account={account} />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavbarComponent;
