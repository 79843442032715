function ErrorComponent({ error }) {
  // const defaultWrapperStyle = {
  //   zIndex: 1000,
  //   position: 'absolute',
  //   left: 0,
  //   top: 0,
  //   backgroundColor: 'rgba(252,252,252,0.1)',
  //   width: '100%',
  //   height: '100%',
  // };

  return (
    <div>
      <p>Something went wrong</p>
      {process.env.NODE_ENV !== 'production' && (
        <pre>{JSON.stringify(error, undefined, 2)}</pre>
      )}
    </div>
  );
}

export default ErrorComponent;
