/* eslint-disable import/no-cycle */
import { useLocation } from 'react-router-dom';

import reduceChildRoutes from './reduce_child_routes';

const SidebarNavList = (props) => {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;

  const childRoutes = pages.reduce(
    (items, page) => reduceChildRoutes({ items, page, currentRoute, depth }),
    []
  );

  return childRoutes;
};

export default SidebarNavList;
