import { Outlet } from 'react-router-dom';
import { useQuery } from '@apollo/client';

import get from 'lodash.get';

import Wrapper from './components/wrapper';
import Sidebar from './components/sidebar';
import Main from './components/main';
import Navbar from './components/navbar';
import Content from './components/content';
import Footer from './components/footer';
import Unauthorized from './components/unauthorized';
import Error from './components/error';

import settingsQuery from '../queries/settings_query';

import { renderOverlay } from '../components/render_helpers';

const DashboardLayout = ({ children }) => {
  const {
    data: pageData,
    loading: pageLoading,
    error: pageError,
  } = useQuery(settingsQuery);

  const isAuthenticationError = (error) => {
    return (
      error &&
      get(error, ['networkError', 'result', 'errors', 0, 'extensions', 'code']) ===
        'UNAUTHENTICATED'
    );
  };

  const renderError = () => {
    return (
      <Main>
        <Content>
          <Error />
        </Content>
      </Main>
    );
  };
  const renderUnauthorized = () => {
    return (
      <Main>
        <Navbar />
        <Content>
          <Unauthorized />
        </Content>
      </Main>
    );
  };
  const renderAuthorized = () => {
    return (
      <>
        <Sidebar />
        <Main>
          <Navbar />
          <Content>
            {children}
            <Outlet />
          </Content>
          <Footer />
        </Main>
      </>
    );
  };

  return (
    <Wrapper>
      {renderOverlay(pageLoading)}
      {pageError && isAuthenticationError(pageError) && renderUnauthorized()}
      {pageError && !isAuthenticationError(pageError) && renderError()}
      {!pageLoading &&
        !pageError &&
        !get(pageData, 'settings.validTenancy') &&
        renderUnauthorized()}
      {!pageLoading &&
        !pageError &&
        get(pageData, 'settings.validTenancy') &&
        renderAuthorized()}
    </Wrapper>
  );
};

export default DashboardLayout;
