import { Col, Container, Row } from 'react-bootstrap';

import Main from './components/main';
import Content from './components/content';

const DeveloperView = ({ error, componentStack }) => {
  return (
    <div>
      <h2>Error Boundary</h2>
      <pre className="h5">{error && error.toString()}</pre>
      <br />
      <pre className="h6">{error && componentStack}</pre>
    </div>
  );
};

const UserView = () => {
  return (
    <>
      <h2>Something went wrong</h2>
      <br />
      <p>This error has been logged and we will get to it shortly.</p>
      <br />
      <a className="btn btn-primary btn-block" href="/">
        Home
      </a>
    </>
  );
};

const ErrorBoundaryLayout = ({ error, componentStack }) => {
  return (
    <div>
      {process.env.NODE_ENV === 'development' ? (
        <Main>
          <Content>
            <DeveloperView error={error} componentStack={componentStack} />
          </Content>
        </Main>
      ) : (
        <Main className="d-flex w-100 justify-content-center">
          <Container className="d-flex flex-column">
            <Row className="h-100">
              <Col sm="10" md="8" lg="6" className="mx-auto d-table h-100">
                <div className="d-table-cell align-middle">
                  <UserView />
                </div>
              </Col>
            </Row>
          </Container>
        </Main>
      )}
    </div>
  );
};

export default ErrorBoundaryLayout;
