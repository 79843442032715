import { Children, cloneElement, Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, OverlayTrigger } from 'react-bootstrap';

class Confirm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: !!this.props.visible,
    };
    this.onButtonClick = this.onButtonClick.bind(this);
    this.onHide = this.onHide.bind(this);

    this.onCancel = this.onCancel.bind(this);
    this.onMiddle = this.onMiddle.bind(this);
    this.onConfim = this.onConfim.bind(this);
  }

  onButtonClick(e) {
    if (this.props.stopPropagation) {
      e.stopPropagation();
    }
    this.setState({ isOpened: true });
  }

  onHide(e) {
    if (e) {
      e.stopPropagation();
    }
    this.setState({ isOpened: false });
  }

  onCancel(e) {
    e.stopPropagation();
    this.setState({ isOpened: false });
    if (this.props.onCancel) {
      this.props.onCancel(e);
    }
  }

  onMiddle(e) {
    e.stopPropagation();
    this.setState({ isOpened: false });
    if (this.props.onMiddle) {
      this.props.onMiddle(e);
    }
  }

  onConfim(e) {
    e.stopPropagation();
    this.setState({ isOpened: false });
    if (this.props.onConfirm) {
      this.props.onConfirm(e);
    }
  }

  renderCancelButton() {
    if (this.props.showCancelButton) {
      const { cancelVariant, cancelStyle, cancelText } = this.props;

      return (
        <Button variant={cancelVariant} style={cancelStyle} onClick={this.onCancel}>
          {cancelText}
        </Button>
      );
    }
    return undefined;
  }

  renderMiddleButton() {
    if (this.props.showMiddleButton) {
      const { dataId, middleId, middleVariant, middleStyle, middleText } = this.props;

      return (
        <Button
          id={middleId}
          data-id={dataId}
          variant={middleVariant}
          style={middleStyle}
          onClick={this.onMiddle}
        >
          {middleText}
        </Button>
      );
    }
    return undefined;
  }

  renderConfirmButton() {
    const { dataId, confirmId, confirmVariant, confirmStyle, confirmText } = this.props;

    return (
      <Button
        id={confirmId}
        data-id={dataId}
        variant={confirmVariant}
        style={confirmStyle}
        onClick={this.onConfim}
      >
        {confirmText}
      </Button>
    );
  }

  renderBody() {
    const { body } = this.props;
    if (Array.isArray(body)) {
      return (
        <>
          {body.map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </>
      );
    }
    return body;
  }

  renderModal() {
    const { title } = this.props;

    return (
      <Modal show={this.state.isOpened} onHide={this.onHide} centered>
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{this.renderBody()}</Modal.Body>
        <Modal.Footer>
          {this.renderCancelButton()}
          {this.renderMiddleButton()}
          {this.renderConfirmButton()}
        </Modal.Footer>
      </Modal>
    );
  }

  renderDefault(modal) {
    const { buttonText, buttonVariant, buttonStyle } = this.props;

    return (
      <Button variant={buttonVariant} onClick={this.onButtonClick} style={buttonStyle}>
        {buttonText}
        {modal}
      </Button>
    );
  }

  renderCustom(modal) {
    const btn = Children.only(this.props.children);
    const content = cloneElement(
      btn,
      {
        onClick: this.onButtonClick,
        style: this.props.style,
      },
      btn.props.children,
      modal
    );
    if (this.props.tip) {
      return (
        <OverlayTrigger placement="top" overlay={this.props.tip}>
          {content}
        </OverlayTrigger>
      );
    }
    return content;
  }

  render() {
    const modal = this.renderModal();
    if (this.props.children) {
      return this.renderCustom(modal);
    }
    return this.renderDefault(modal);
  }
}

Confirm.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  // tip: PropTypes.node,
  stopPropagation: PropTypes.bool,
  buttonText: PropTypes.node,
  buttonVariant: PropTypes.string,
  buttonStyle: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  cancelText: PropTypes.node,
  cancelVariant: PropTypes.string,
  cancelStyle: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  confirmText: PropTypes.node,
  confirmVariant: PropTypes.string,
  confirmStyle: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  middleText: PropTypes.node,
  middleVariant: PropTypes.string,
  middleStyle: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onMiddle: PropTypes.func,
  showCancelButton: PropTypes.bool,
  showMiddleButton: PropTypes.bool,
};

Confirm.defaultProps = {
  buttonText: 'Action',
  buttonVariant: 'primary',
  confirmText: 'Confirm',
  confirmVariant: 'danger',
  showCancelButton: true,
  cancelText: 'Cancel',
  cancelVariant: 'light',
  showMiddleButton: false,
  middleText: 'Middle',
  middleVariant: 'light',
  visible: false,
  stopPropagation: false,
  onCancel: null,
  onMiddle: null,
  buttonStyle: {},
  middleStyle: {},
  cancelStyle: {},
  confirmStyle: {},
};

export default Confirm;
